import { Observable, of } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	exhaustMap,
	map,
	switchMap,
	take,
} from 'rxjs/operators';

import {
	AbstractControl,
	AsyncValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import { ApiInjector } from '@api/api.module';
import { UserValidationType, ValidationMode } from '@api/models/enums';
import { UserValidationEmailRequest } from '@api/models/user-validation-email.request';
import { UserValidationNameRequest } from '@api/models/user-validation-name.request';
import { ValidationResultResponse } from '@api/models/validation-result.response';
import { ValidatorsService } from '@api/services/validators.service';
import { PlcValidationErrorType } from '@components/models/foms.models';

const EMAIL_NOT_VALID_ERROR = { [PlcValidationErrorType.emailNotValid]: true };

export class PlcUserValidators {
	/**
	 * Check if email has a correct format
	 * - Valid: email@domain.com
	 * - Invalid: email@domain.c, emaildomain.com, @mail.com
	 * @returns ```{ emailNotValid: true }``` if validation is incorrect
	 */
	public static email = (
		control: AbstractControl,
	): ValidationErrors | null => {
		const regex = new RegExp(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
		const valid = regex.test(control.value);

		return valid || !control.value ? null : EMAIL_NOT_VALID_ERROR;
	};
}

const EMAIL_EXISTS_ERROR = { [PlcValidationErrorType.emailExists]: true };
const EMAIL_DOES_NOT_EXISTS_ERROR = {
	[PlcValidationErrorType.emailDoesNotExists]: true,
};
const NAME_EXISTS_ERROR = { [PlcValidationErrorType.nameExists]: true };

const INPUT_DEBOUNCE_TIME = 500;
export class PlcUserAsyncValidators {
	/**
	 * Check if value is equals to provided as argument, otherwise a http call is performed
	 * to validationService to fetch if control´s value already exists in the database
	 * @param validationService ValidationService from ```@core/services/http/validation-service``` to perform the http call
	 * @param valueToCompare the value to compare the control value
	 * @param propertyName the property name to validate
	 * @returns ```{ nameExists: true }``` or ```{ emailExists: true }``` if validation is incorrect
	 */
	public static isNewOrEqual(
		valueToCompare: string,
		propertyName: 'name' | 'email',
	): AsyncValidatorFn {
		return (
			control: AbstractControl,
		): Observable<ValidationErrors | null> => {
			if (!control || !control.valueChanges || control.pristine)
				return of(null);

			const validationService =
				ApiInjector.instance.get(ValidatorsService);

			return control.valueChanges.pipe(
				distinctUntilChanged(),
				debounceTime(INPUT_DEBOUNCE_TIME),
				take(1),
				switchMap((value) => {
					if (valueToCompare === value) return of(null);

					const error: ValidationErrors = {
						[`${propertyName}Exists`]: true,
					};

					let request:
						| UserValidationNameRequest
						| UserValidationEmailRequest;
					if (propertyName === 'email')
						request = {
							_type: UserValidationType.Email,
							mode: ValidationMode.New,
							email: value,
						};
					else if (propertyName === 'name')
						request = {
							_type: UserValidationType.Name,
							mode: ValidationMode.New,
							name: value,
						};

					return validationService
						.validateUser(request)
						.pipe(
							map(({ valid }: ValidationResultResponse) =>
								!valid ? error : null,
							),
						);
				}),
			);
		};
	}

	/**
	 * Checks if a given email exists in the database performing a http request
	 * to the backend to fetch existing users by **email**
	 * @param validationService an instance of ```@core/services/http/validation.service```
	 * to fetch users from the BackEnd
	 * @param mustExists optional flag to check if the value should exists or
	 * not in the database. Default value: *false*
	 * @returns ```{ emailExists: true }``` or ```{ emailDoesNotExists: true }```
	 * if validation is incorrect
	 */
	public static emailExists(mustExists = false): AsyncValidatorFn {
		return (
			control: AbstractControl,
		): Observable<ValidationErrors | null> => {
			const validationService =
				ApiInjector.instance.get(ValidatorsService);

			return control.valueChanges.pipe(
				distinctUntilChanged(),
				debounceTime(INPUT_DEBOUNCE_TIME),
				take(1),
				exhaustMap((value: string) =>
					validationService
						.validateUser({
							_type: UserValidationType.Email,
							mode: mustExists
								? ValidationMode.Existing
								: ValidationMode.New,
							email: value,
						})
						.pipe(
							map(({ valid }: ValidationResultResponse) => {
								if (!mustExists)
									return !valid ? EMAIL_EXISTS_ERROR : null;
								else
									return !valid
										? EMAIL_DOES_NOT_EXISTS_ERROR
										: null;
							}),
						),
				),
			);
		};
	}

	/**
	 * Checks if a given name exists in the database performing a http request
	 * to the backend to fetch existing users by **name**
	 * @param validationService an instance of ```@core/services/http/validation.service```
	 * to fetch users from the BackEnd
	 * @returns ```{ nameExists: true }``` if validation is incorrect
	 */
	public static nameExists(): AsyncValidatorFn {
		return (
			control: AbstractControl,
		): Observable<ValidationErrors | null> => {
			const validationService =
				ApiInjector.instance.get(ValidatorsService);

			return control.valueChanges.pipe(
				distinctUntilChanged(),
				debounceTime(INPUT_DEBOUNCE_TIME),
				take(1),
				switchMap((value: string) =>
					validationService
						.validateUser({
							_type: UserValidationType.Name,
							mode: ValidationMode.New,
							name: value,
						})
						.pipe(
							map(({ valid }: ValidationResultResponse) =>
								!valid ? NAME_EXISTS_ERROR : null,
							),
						),
				),
			);
		};
	}
}
